var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"tag-autocomplete",attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","hide-details":"","multiple":"","outlined":"","disabled":_vm.disabled,"loading":_vm.loading || _vm.btxLoading,"placeholder":"Search Tags...","small-chips":"","solo":"","flat":"","return-object":"","menu-props":{
		contentClass: 'tag-autocomplete-list',
	}},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
	var attrs = ref.attrs;
	var item = ref.item;
	var parent = ref.parent;
	var selected = ref.selected;
return [_c('v-list-item',[(item === Object(item))?_c('v-chip',_vm._b({style:({ 'background-color': item.color }),attrs:{"text-color":"white","input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()],1)]}},{key:"item",fn:function(ref){
	var index = ref.index;
	var item = ref.item;
return [_c('v-chip',{style:({ 'background-color': item.color }),attrs:{"text-color":"white","dark":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{},[_c('div',{staticClass:"mx-2 my-2 d-flex tag-autocomplete-new-chip"},[_c('div',{staticStyle:{"width":"70px","border-right":"solid 1px #777"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
	var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticStyle:{"height":"34px","font-size":"36px !important"},style:({ color: _vm.item_color })},[_vm._v("mdi-record")]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('div',{staticClass:"bg-white mt-2",staticStyle:{"height":"140px"}},[_c('v-row',{staticClass:"px-2",staticStyle:{"width":"366px","margin":"0px !important"}},_vm._l((_vm.colors),function(color,index){return _c('v-col',{key:("mdi-record-" + index),staticClass:"text-center bg-hover-tag",staticStyle:{"min-width":"50px","max-width":"50px","padding":"4px !important"},on:{"click":function($event){return _vm.select_color(color)}}},[(_vm.item_color == color)?_c('v-icon',{staticStyle:{"height":"34px","width":"34px","font-size":"42px !important"},style:({ color: color })},[_vm._v("mdi-check-circle")]):_c('v-icon',{staticStyle:{"height":"34px","width":"34px","font-size":"48px !important"},style:({ color: color })},[_vm._v("mdi-record")])],1)}),1)],1)])],1),_c('div',{staticClass:"px-4 cursor-pointer",staticStyle:{"width":"80%"},on:{"click":function($event){return _vm.create_tag()}}},[_c('p',{staticClass:"m-0"},[_vm._v(" Create a new tag \""),_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(_vm.search))]),_vm._v("\" ")])])])])]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }