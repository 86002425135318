<template>
	<div class="">
		<Dialog :dialog="dialog" :dialogWidth="600">
			<template v-slot:title> Upload Folder</template>
			<template v-slot:body>
				<v-form
					ref="uploadFolderForm"
					v-model="form_valid"
					lazy-validation
					v-on:submit.stop.prevent="submit_file()"
				>
					<v-row class="attach-dialog upload-template-dialog">
						<v-col md="12">
							<p class="font-level-1">Select Folder to Upload</p>
							<div class="border-light-grey text-center py-5 mb-2">
								<v-icon x-large>mdi-cloud-upload-outline</v-icon>
								<br />
								<br />
								<v-btn
									tile
									:disabled="file_loading"
									class="border-light-grey"
									v-on:click="select_file()"
									text
									color="blue darken-4"
									>Choose File</v-btn
								>
								<v-file-input
									webkitdirectory
									mozdirectory
									v-on:change="update_files($event)"
									class="d-none"
									ref="upload-file"
									multiple
								></v-file-input>
								<br />
								<br />
								<span>Maximum File Size: {{ max_file_size() }}</span>
								<br />
								<br />
								<template v-for="(row, index) in folders">
									<v-tooltip :key="`tooltip-file-${index}`" top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												:disabled="file_loading"
												small
												close
												v-on:click:close="remove_folder()"
												v-bind="attrs"
												v-on="on"
												class="mx-1 mb-2"
												:key="`chip-file-${index}`"
												color="blue darken-4 white--text"
												>{{ row }}</v-chip
											>
										</template>
										<span> {{ formatBytes(total_bytes) }} </span>
									</v-tooltip>
								</template>
							</div>
							<v-row dense>
								<v-col sm="4" cols="12" class="">
									<label for="reference-no" class="btx-label mt-2">Reference No</label>
								</v-col>
								<v-col sm="8" cols="12" class="">
									<TextInput
										dense
										id="reference-no"
										hide-details
										v-model="upload.reference"
										placeholder="Enter reference No"
										:disabled="file_loading"
										:loading="file_loading"
										class="mt-0"
									></TextInput>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col sm="4" cols="12" class="">
									<label for="file-tag" class="btx-label mt-2">Tags</label>
								</v-col>
								<v-col sm="8" cols="12" class="">
									<TagAutoComplete
										:disabled="file_loading"
										:btx-loading="file_loading"
										id="file-tag"
										v-model="upload.raw_tags"
										v-on:change="update_tags()"
									></TagAutoComplete>
								</v-col>
							</v-row>
							<v-row>
								<v-col sm="4" cols="12">
									<label for="file-start-date" class="btx-label mt-2">Start Date</label>
								</v-col>
								<v-col sm="8" cols="12">
									<DatePicker
										dense
										id="file-start-date"
										hide-details
										v-model="upload.start_date"
										placeholder="Select Start Date..."
										:disabled="file_loading"
										:loading="file_loading"
										:min-date="formatDateRaw()"
									></DatePicker>
								</v-col>
							</v-row>
							<v-row>
								<v-col sm="4" cols="12">
									<label for="file-expiry-date" class="btx-label mt-2">Expiry Date</label>
								</v-col>
								<v-col sm="8" cols="12">
									<DatePicker
										dense
										id="file-expiry-date"
										hide-details
										v-model="upload.expiry_date"
										placeholder="Select Expiry Date..."
										:disabled="file_loading"
										:loading="file_loading"
										:min-date="upload.start_date"
									></DatePicker>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col sm="4" cols="12" class="">
									<label for="file-share-with" class="btx-label mt-2">Share with</label>
								</v-col>
								<v-col sm="8" cols="12" class="">
									<search-members
										id="file-share-with"
										:disabled="file_loading"
										:btx-loading="file_loading"
										dense
										multiple
										:default-member="default_member"
										v-model="upload.share_with"
									></search-members>
								</v-col>
							</v-row>
							<div class="mt-2 dense-list" style="max-height: calc(100vh - 300px); overflow: auto">
								<v-list two-line :disabled="file_loading">
									<v-list-item
										v-if="default_member"
										:class="{
											'border-bottom-light-grey': upload.share_with.length,
										}"
									>
										<v-list-item-action class="drive-action-btn">
											<v-btn icon color="red lighten-1" disabled>
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</v-list-item-action>
										<v-list-item-avatar>
											<img
												:src="
													default_member.profile ? default_member.profile : $assetURL('media/users/blank.png')
												"
											/>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="default_member.display_name"></v-list-item-title>
											<v-list-item-subtitle v-html="default_member.email"></v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action>
											<v-btn color="blue darken-4 white--text" class="text-capitalize" text> Owner </v-btn>
										</v-list-item-action>
									</v-list-item>
									<v-list-item
										v-for="(item, index) in upload.share_with"
										:key="index"
										:value="item.id"
										:class="{
											'border-bottom-light-grey': upload.share_with[index + 1],
										}"
									>
										<v-list-item-action class="drive-action-btn">
											<v-btn icon color="red lighten-1" v-on:click.stop.prevent="delete_member(item)">
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</v-list-item-action>
										<v-list-item-avatar>
											<img :src="item.profile ? item.profile : $assetURL('media/users/blank.png')" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="item.display_name"></v-list-item-title>
											<v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action v-if="false">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														color="blue darken-4 white--text"
														v-bind="attrs"
														v-on="on"
														class="text-capitalize"
													>
														{{ item.permission }}
														<v-icon small>mdi-arrow-down</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item v-on:click="item.permission = 'viewer'">
														<v-list-item-title>Viewer</v-list-item-title>
													</v-list-item>
													<v-list-item v-on:click="item.permission = 'editor'">
														<v-list-item-title>Editor</v-list-item-title>
													</v-list-item>
												</v-list>
											</v-menu>
										</v-list-item-action>
									</v-list-item>
								</v-list>
							</div>

							<v-row dense>
								<v-col sm="12" cols="12">
									<v-checkbox
										v-model="upload.is_public"
										label="Is Public?"
										id="file-is-public"
										:true-value="1"
										:false-value="0"
										hide-details
										:disabled="file_loading"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col sm="12" cols="12">
									<v-checkbox
										v-model="upload.set_reminder"
										label="Set Reminder"
										id="file-reminder"
										:true-value="1"
										:false-value="0"
										:disabled="file_loading"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-row v-if="upload.set_reminder">
								<v-col sm="4" cols="12" class="">
									<label for="reminder-type" class="btx-label mt-2 required">Reminder Type</label>
								</v-col>
								<v-col sm="8" cols="12" class="">
									<AutoCompleteInput
										dense
										id="reminder-type"
										hide-details
										v-model="upload.reminder.type"
										placeholder="Select Reminder Type"
										:items="reminder_type_items"
										:disabled="file_loading"
										:loading="file_loading"
										item-text="text"
										item-value="value"
										v-on:change="get_reminder_values()"
									></AutoCompleteInput>
								</v-col>
								<v-col sm="4" cols="12" class="">
									<label for="reminder-start-date" class="btx-label mt-2 required"
										><template v-if="upload.reminder.type != 1">Start </template>Date</label
									>
								</v-col>
								<v-col sm="8" cols="12" class="">
									<DatePicker
										dense
										id="reminder-start-date"
										hide-details
										v-model="upload.reminder.start_date"
										:placeholder="upload.reminder.type != 1 ? 'Select Start Date...' : 'Select Date...'"
										:disabled="file_loading"
										:loading="file_loading"
										v-on:change="get_reminder_values()"
										:min-date="formatDateRaw()"
										:rules="[
											vrules.required(
												upload.reminder.start_date,
												upload.reminder.type != 1 ? 'Start Date' : 'Date'
											),
										]"
									></DatePicker>
								</v-col>
								<template v-if="upload.reminder.type != 1">
									<v-col sm="4" cols="12" class="">
										<label for="reminder-frequency" class="btx-label mt-2 required">Frequency</label>
									</v-col>
									<v-col sm="8" cols="12" class="">
										<TextInput
											dense
											id="reminder-frequency"
											hide-details
											type="number"
											v-model="upload.reminder.frequency"
											placeholder="Enter Frequency..."
											:disabled="file_loading"
											:loading="file_loading"
											v-on:change="get_reminder_values()"
											:rules="[vrules.required(upload.reminder.frequency, 'Frequency')]"
											class="mt-0"
										></TextInput>
									</v-col>
									<v-col sm="4" cols="12" class="">
										<label for="reminder-end-date" class="btx-label mt-2 required">End Date</label>
									</v-col>
									<v-col sm="8" cols="12" class="">
										<DatePicker
											dense
											:rules="[vrules.required(upload.reminder.end_date, 'End Date')]"
											id="reminder-end-date"
											hide-details
											v-model="upload.reminder.end_date"
											placeholder="Select End Date..."
											:disabled="file_loading"
											:loading="file_loading"
											readonly
										></DatePicker>
									</v-col>
								</template>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="file_loading" v-on:click="$emit('close', true)">
					Cancel
				</v-btn>
				<v-btn
					class="white--text"
					:loading="file_loading"
					:disabled="file_loading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="upload_folders()"
				>
					Upload
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="exist_dialog" :dialogWidth="768">
			<template v-slot:title>
				<v-icon color="blue darken-4" class="mr-2">mdi-alert</v-icon> Action Required
			</template>
			<template v-slot:body>
				<div>
					<p class="font-level-4-bold mb-4">Below folders are already exists.</p>
					<table width="100%" style="table-layout: fixed" class="alternate-row">
						<tr v-for="(file, index) in exist_files" :key="`exist-tr-${index}`" class="border-light-grey">
							<td valign="middle" width="30px" align="center">{{ index + 1 }}.</td>
							<td valign="middle" width="70%" class="border-left-light-grey pl-2 py-2">
								<table width="100%">
									<tr>
										<td width="30%">Folder Name:</td>
										<td>
											<span class="font-level-2-bold">{{ file.name }}</span>
										</td>
									</tr>
									<tr>
										<td width="30%">Location:</td>
										<td>
											<span class="font-level-2-bold">{{ file.path }}</span>
										</td>
									</tr>
									<tr v-if="file.action == 'copy'">
										<td width="30%">Folder Name (copy):</td>
										<td>
											<span class="font-level-2-bold">{{ file.new_name }}</span>
										</td>
									</tr>
								</table>
							</td>
							<td valign="middle" align="right" class="py-1">
								<v-radio-group hide-details class="mt-0 mb-0 py-0 pb-0" row v-model="file.action">
									<v-radio color="blue darken-4" label="Create another copy?" value="copy"></v-radio>
									<v-radio color="blue darken-4" label="Ignore" value="ignore"></v-radio>
								</v-radio-group>
							</td>
						</tr>
					</table>
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="file_loading" v-on:click="exist_dialog = false">
					Cancel
				</v-btn>
				<v-btn
					class="white--text"
					:loading="file_loading"
					:disabled="file_loading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="submit_file()"
				>
					Submit
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { toNumber, round, findIndex, toSafeInteger, uniq, sum } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TagAutoComplete from "@/view/components/TagAutoComplete";
import SearchMembers from "@/view/components/SearchMembers";
import { POST, GET, QUERY, UPLOAD } from "@/core/services/store/request.module";
import { ERROR } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import ObjectPath from "object-path";
import { AES, enc } from "crypto-js";

export default {
	name: "KTUPLOAD",
	components: {
		Dialog,
		TextInput,
		DatePicker,
		TagAutoComplete,
		AutoCompleteInput,
		"search-members": SearchMembers,
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			exist_files: [],
			exist_dialog: false,
			form_valid: true,
			file_loading: false,
			reminder_type_items: [],
			files: [],
			folders: [],
			upload: {
				reference: null,
				tags: [],
				raw_tags: [],
				start_date: null,
				expiry_date: null,
				is_public: 0,
				share_with: [],
				set_reminder: 0,
				reminder: {
					type: 1,
					start_date: null,
					frequency: null,
					end_date: null,
					total: null,
				},
			},
			default_member: {
				id: 0,
				permission: null,
				title: null,
				first_name: null,
				last_name: null,
				phone_number: null,
				display_name: null,
				email: null,
				profile: null,
			},
			timeout: null,
			timeout_limit: 500,
		};
	},
	methods: {
		update_tags() {
			this.upload.tags = this.upload.raw_tags;
		},
		get_reminder_values() {
			const _this = this;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				_this.$store
					.dispatch(QUERY, {
						url: "upload/reminder-values",
						data: _this.upload.reminder,
					})
					.then((response) => {
						_this.upload.reminder.end_date = response.last_date;
						_this.upload.reminder.total = response.total;
					})
					.catch((error) => {
						console.log({ error });
					});
			}, _this.timeout_limit);
		},
		delete_member(member) {
			const index = findIndex(this.upload.share_with, { id: member.id });
			this.upload.share_with.splice(index, 1);
		},
		get_options() {
			this.$store
				.dispatch(GET, { url: "upload/options?is-folder=1" })
				.then((response) => {
					this.upload.reference = response.barcode;
					this.default_member = response.default_member;
					this.reminder_type_items = response.reminder_type_items;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					// console.log({param})
				});
		},
		update_files(param) {
			if (param) {
				const files = [];
				const folders = [];
				const total_size = [];
				const max_size = toNumber(this.$uploadSizeLIMIT);
				const max_file = toNumber(this.$uploadLIMIT);
				for (let i = 0; i < param.length; i++) {
					const size = round(toNumber(param[i].size / 1024), 2);
					if (size <= max_size && this.files.length < max_file) {
						const webkitRelativePath = param[i].webkitRelativePath;
						const folder = webkitRelativePath.split("/")[0];
						total_size.push(param[i].size);
						folders.push(folder);
						files.push(param[i]);
					}
				}
				this.files = files;
				this.total_bytes = sum(total_size);
				this.folders = uniq(folders);
			}
		},
		get_file_size(size) {
			return round(toNumber(size) / 1024, 2);
		},
		remove_folder() {
			this.files = [];
			this.total_bytes = 0;
			this.folders = [];
		},
		max_file_size() {
			return this.humanFileSize(toNumber(this.$uploadSizeLIMIT) * 1024, true);
		},
		select_file() {
			this.$nextTick(() => {
				this.$refs["upload-file"].reset();
				this.$refs["upload-file"].$refs["input"].click();
			});
		},
		validate_name() {
			let _this = this;
			return new Promise((resolve, reject) => {
				let linked_id = 0;
				let folder = ObjectPath.get(_this.$route, "query.folder");
				if (folder) {
					let decrypted = AES.decrypt(folder, _this.$enc_key);
					linked_id = toSafeInteger(JSON.parse(decrypted.toString(enc.Utf8)));
				}
				let formData = new Array();
				for (let i = 0; i < _this.files.length; i++) {
					formData.push({ path: _this.files[i].webkitRelativePath, linked_id: linked_id });
				}
				_this.$store
					.dispatch(POST, {
						url: "validate-folders",
						data: { folders: formData },
					})
					.then((output) => {
						resolve(output);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		upload_folders() {
			this.$refs.uploadFolderForm.validate();

			if (!this.files.length) {
				this.$store.dispatch(ERROR, [{ model: true, message: "Please select atleast one folder" }]);
				return false;
			}

			this.file_loading = true;
			this.exist_dialog = false;

			this.validate_name()
				.then((response) => {
					if (response.exist) {
						this.exist_dialog = true;
						this.exist_files = response.folders;
						this.file_loading = false;
					} else {
						this.file_loading = true;
						this.submit_file();
					}
				})
				.catch(() => {
					this.file_loading = false;
				});
		},
		submit_file() {
			try {
				const _this = this;

				_this.$refs.uploadFolderForm.validate();

				if (!_this.files.length) {
					_this.$store.dispatch(ERROR, [{ model: true, message: "Please select atleast one folder" }]);
					return false;
				}

				const formErrors = _this.validateForm(_this.$refs.uploadFolderForm);
				if (formErrors.length) {
					_this.$store.dispatch(ERROR, formErrors);
					return false;
				}

				if (!_this.$refs.uploadFolderForm.validate()) {
					return false;
				}

				const form_data = new FormData();

				for (let i = 0; i < _this.files.length; i++) {
					form_data.append(`folders[${i}][file]`, _this.files[i], _this.files[i].name);
					form_data.append(`folders[${i}][path]`, _this.files[i].webkitRelativePath);
				}

				for (let z = 0; z < _this.upload.tags.length; z++) {
					form_data.append(`tags[${z}][text]`, _this.upload.tags[z].text);
					form_data.append(`tags[${z}][color]`, _this.upload.tags[z].color);
				}

				for (let x = 0; x < _this.upload.share_with.length; x++) {
					form_data.append(`share_with[${x}][id]`, _this.upload.share_with[x].id);
					form_data.append(`share_with[${x}][permission]`, _this.upload.share_with[x].permission);
				}

				form_data.append("reference", _this.upload.reference);

				const folder = ObjectPath.get(_this.$route, "query.folder");

				if (folder) {
					const decrypted = AES.decrypt(folder, _this.$enc_key);

					const linked_id = toSafeInteger(JSON.parse(decrypted.toString(enc.Utf8)));

					if (linked_id) {
						form_data.append("linked_id", linked_id);
					}
				}

				if (_this.upload.start_date) {
					form_data.append("start_date", _this.upload.start_date);
				}

				if (_this.upload.expiry_date) {
					form_data.append("expiry_date", _this.upload.expiry_date);
				}

				form_data.append("is_public", _this.upload.is_public);
				form_data.append("set_reminder", _this.upload.set_reminder);

				if (_this.upload.set_reminder == 1) {
					form_data.append("reminder[type]", _this.upload.reminder.type);

					if (_this.upload.reminder.start_date) {
						form_data.append("reminder[start_date]", _this.upload.reminder.start_date);
					}

					if (_this.upload.reminder.frequency) {
						form_data.append("reminder[frequency]", _this.upload.reminder.frequency);
					}

					if (_this.upload.reminder.end_date) {
						form_data.append("reminder[end_date]", _this.upload.reminder.end_date);
					}
				}

				if (_this.exist_files.length) {
					for (let e = 0; e < _this.exist_files.length; e++) {
						form_data.append(`exist_files[${e}][id]`, _this.exist_files[e].id);
						form_data.append(`exist_files[${e}][path]`, _this.exist_files[e].path);
						form_data.append(`exist_files[${e}][name]`, _this.exist_files[e].name);
						form_data.append(`exist_files[${e}][action]`, _this.exist_files[e].action);
					}
				}

				_this.file_loading = true;

				_this.$store
					.dispatch(UPLOAD, { url: "folders", data: form_data })
					.then(() => {
						_this.$emit("success", true);
						EventBus.$emit("refresh-listing", true);
						EventBus.$emit("refresh-storage", true);
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						_this.file_loading = false;
					});
			} catch (error) {
				console.log({ error });
			}
		},
	},
	mounted() {
		this.get_options();
	},
};
</script>
