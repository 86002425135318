<template>
	<div class="d-flex flex-column flex-root" v-if="isAuthenticated">
		<div class="two-factor-notice" v-if="!two_factor_dialog && !currentUser.two_factor_enabled">
			Secure your account by enabling Two-Factor Authentication (2FA) –
			<span
				@click="two_factor_dialog = true"
				class="cursor-pointer"
				style="color: black; font-weight: 600"
				>Activate it here!</span
			>
		</div>
		<!-- begin:: Header Mobile -->
		<KTHeaderMobile></KTHeaderMobile>
		<!-- end:: Header Mobile -->

		<Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

		<!-- begin::Body -->
		<div class="d-flex flex-row flex-column-fluid page">
			<!-- begin:: Aside Left -->
			<KTAside v-if="asideEnabled"></KTAside>
			<!-- end:: Aside Left -->

			<div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
				<!-- begin:: Header -->
				<KTHeader></KTHeader>
				<!-- end:: Header -->

				<!-- begin:: Content -->
				<div id="kt_content" class="content d-flex flex-column flex-column-fluid">
					<!-- begin:: Content Head -->

					<!-- begin:: Content Body -->
					<div class="d-flex flex-column-fluid">
						<v-app>
							<template v-if="errors && errors.length > 0">
								<div class="btx-alert">
									<template v-for="(error, index) in errors">
										<v-snackbar
											:key="`error-${index}`"
											v-model="error.model"
											transition="slide-y-transition"
											:timeout="error.timeout"
											color="red lighten-1"
											elevation="5"
											absolute
											top
											right
										>
											<v-icon>mdi-alert-circle-outline</v-icon>
											<span class="ml-2" v-html="error.message"></span>

											<template v-slot:action="{ attrs }">
												<v-icon color="white" v-bind="attrs" small v-on:click="error.model = false"
													>mdi-close</v-icon
												>
											</template>
										</v-snackbar>
									</template>
								</div>
							</template>
							<template v-if="messages && messages.length > 0">
								<div class="btx-alert">
									<template v-for="(success, index) in messages">
										<v-snackbar
											:key="`success-${index}`"
											v-model="success.model"
											transition="slide-y-transition"
											:timeout="success.timeout"
											color="green"
											elevation="5"
											absolute
											top
											right
										>
											<v-icon>mdi-alert-circle-outline</v-icon>
											<span class="ml-2" v-html="success.message"></span>

											<template v-slot:action="{ attrs }">
												<v-icon color="white" v-bind="attrs" small v-on:click="success.model = false"
													>mdi-close</v-icon
												>
											</template>
										</v-snackbar>
									</template>
								</div>
							</template>
							<v-container class="bg-white main-container px-0" fluid>
								<!-- <transition name="fade-in-up"> -->
								<router-view />
								<!-- </transition> -->
								<div
									class="zip-layout"
									v-if="fdownloads.length"
									:style="$vuetify.breakpoint.xl ? 'width: 400px' : 'width: 300px'"
								>
									<v-expansion-panels accordion flat tile v-model="download_box">
										<v-expansion-panel>
											<v-expansion-panel-header>
												<p class="h4">{{ get_download_title() }}</p>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table width="100%" class="download-list">
													<tr v-for="(row, index) in fdownloads" :key="index">
														<td class="py-1 px-2" width="15%" align="left">
															<v-tooltip :key="`tooltip-file-${index}`" top content-class="custom-top-tooltip">
																<template v-slot:activator="{ on, attrs }">
																	<v-icon
																		v-bind="attrs"
																		v-on="on"
																		color="blue darken-4"
																		:disabled="!row.url"
																		v-on:click="download_file(row)"
																		>mdi-download-multiple</v-icon
																	>
																</template>
																<span> Click here to download </span>
															</v-tooltip>
														</td>
														<td class="py-1" width="65%" align="left">
															<span class="font-level-2-bold" v-if="row.status">{{ row.name }} zipped</span>
															<span class="font-level-2-bold" v-else>Zipping {{ row.name }}</span>
														</td>
														<td class="py-1 px-2" width="20%" align="center">
															<template v-if="row.status">
																<v-icon color="green" class="mr-2">mdi-check</v-icon>
																<v-icon color="red" v-on:click="delete_row(index)">mdi-close</v-icon>
															</template>
															<v-progress-circular
																v-else
																indeterminate
																:size="20"
																:width="3"
																color="blue darken-4"
															></v-progress-circular>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
								</div>
							</v-container>
						</v-app>
					</div>
				</div>
			</div>
		</div>
		<KTScrollTop></KTScrollTop>
		<large-upload-template
			large-allowed
			v-if="large_upload_dialog"
			:dialog="large_upload_dialog"
			v-on:close="large_upload_dialog = false"
			v-on:success="large_upload_dialog = false"
		></large-upload-template>
		<upload-template
			v-if="upload_dialog"
			:dialog="upload_dialog"
			v-on:close="upload_dialog = false"
			v-on:success="upload_dialog = false"
		></upload-template>
		<folder-upload-template
			v-if="folder_upload_dialog"
			:dialog="folder_upload_dialog"
			v-on:close="folder_upload_dialog = false"
			v-on:success="folder_upload_dialog = false"
		></folder-upload-template>
		<folder-new-template
			v-if="folder_new_dialog"
			:dialog="folder_new_dialog"
			v-on:close="folder_new_dialog = false"
			v-on:success="folder_new_dialog = false"
		/>
		<two-factor-auth
			:dialog="two_factor_dialog"
			v-on:close="two_factor_dialog = false"
			v-on:success="onLogout()"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
	ADD_BODY_CLASSNAME,
	REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import { ErrorEventBus } from "@/core/lib/message.lib";
import { ERROR } from "@/core/services/store/common.module";
import UploadTemplate from "@/view/components/UploadTemplate";
import TwoFactorAuth from "@/view/components/TwoFactorAuth";
import FolderUploadTemplate from "@/view/components/FolderUploadTemplate";
import FolderNewTemplate from "@/view/components/FolderNewTemplate";
import { EventBus } from "@/core/event-bus/event.bus";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import { GET } from "@/core/services/store/request.module";
import { find, findIndex } from "lodash";
import ObjectPath from "object-path";

export default {
	name: "Layout",
	components: {
		KTAside,
		KTHeader,
		KTHeaderMobile,
		KTScrollTop,
		Loader,
		FolderUploadTemplate,
		UploadTemplate,
		TwoFactorAuth,
		"large-upload-template": UploadTemplate,
		FolderNewTemplate,
	},
	data() {
		return {
			two_factor_dialog: false,
			upload_dialog: false,
			folder_upload_dialog: false,
			large_upload_dialog: false,
			folder_new_dialog: false,
			download_box: 0,
			fdownloads: [],
		};
	},
	beforeMount() {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	},
	methods: {
		onLogout() {
			this.$store
				.dispatch(LOGOUT)
				.then(() => {
					this.$router.push({ name: "login" });
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		get_download_title() {
			const has_false = find(this.fdownloads, { status: false });
			if (has_false) {
				return "Preparing Download";
			}
			return "Download Ready";
		},
		download_file(row) {
			if (row.status && row.url) {
				window.open(row.url, "_blank");
			}
		},
		delete_row(index) {
			this.fdownloads.splice(index, 1);
		},
		bind_pusher(uuid, name) {
			const _this = this;

			_this.download_box = 0;

			_this.fdownloads.push({
				status: false,
				url: null,
				name,
				uuid,
			});

			_this.$pusher.bind(`download-${uuid}`, (row) => {
				const puuid = ObjectPath.get(row, "uuid", null);
				const purl = ObjectPath.get(row, "url", null);
				const pname = ObjectPath.get(row, "name", null);
				const index = findIndex(_this.fdownloads, { uuid: puuid });
				if (index >= 0) {
					_this.fdownloads[index].name = pname;
					_this.fdownloads[index].url = purl;
					_this.fdownloads[index].status = true;
				}
			});
		},
		get_profile() {
			this.$store
				.dispatch(GET, { url: "users/profile" })
				.then((response) => {
					this.$store.dispatch(UPDATE_PERSONAL_INFO, response);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	mounted() {
		// check if current user is authenticated
		if (!this.isAuthenticated) {
			this.$router.push({ name: "login" });
		}

		this.get_profile();

		EventBus.$on("init-download", ({ uuid, name }) => {
			this.bind_pusher(uuid, name);
		});

		ErrorEventBus.$on("set-error", (param) => {
			this.$store.dispatch(ERROR, param);
		});

		EventBus.$on("upload-file", (param) => {
			this.upload_dialog = param;
		});

		EventBus.$on("upload-large-file", (param) => {
			this.large_upload_dialog = param;
		});

		EventBus.$on("upload-folder", (param) => {
			this.folder_upload_dialog = param;
		});

		EventBus.$on("create-folder", (param) => {
			this.folder_new_dialog = param;
		});

		EventBus.$on("upload-dialog", (param) => {
			if (param == "new-folder") {
				this.folder_new_dialog = true;
			}
			if (param == "file-upload") {
				this.upload_dialog = true;
			}
			if (param == "large-file-upload") {
				this.large_upload_dialog = true;
			}
			if (param == "folder-upload") {
				this.folder_upload_dialog = true;
			}
		});

		// Simulate the delay page loading
		setTimeout(() => {
			// Remove page loader after some time
			this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
		}, 2000);
	},
	watch: {
		isAuthenticated(param) {
			if (!param) {
				this.$router.push({ name: "login" });
			}
		},
	},
	beforeDestroy() {
		EventBus.$off("init-download");
		EventBus.$off("upload-file");
		EventBus.$off("upload-large-file");
		EventBus.$off("upload-folder");
		EventBus.$off("create-folder");
		EventBus.$off("upload-dialog");
		ErrorEventBus.$off("set-error");
	},
	computed: {
		...mapGetters(["isAuthenticated", "errors", "messages", "layoutConfig", "currentUser"]),

		/**
		 * Check if the page loader is enabled
		 * @returns {boolean}
		 */
		loaderEnabled() {
			return !/false/.test(this.layoutConfig("loader.type"));
		},

		/**
		 * Check if container width is fluid
		 * @returns {boolean}
		 */
		contentFluid() {
			return this.layoutConfig("content.width") === "fluid";
		},

		/**
		 * Page loader logo image using require() function
		 * @returns {string}
		 */
		loaderLogo() {
			return process.env.BASE_URL + this.layoutConfig("loader.logo");
		},

		/**
		 * Check if the left aside menu is enabled
		 * @returns {boolean}
		 */
		asideEnabled() {
			return !!this.layoutConfig("aside.self.display");
		},
	},
};
</script>
